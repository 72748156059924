
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { getApolloClient } from '../utilities/apolloClient';
import { getNextStaticProps } from '../utilities/getNextStaticProps';
import { Home, SEO } from '../components';
import { DefaultLayout } from '../layouts';
import { getPageByUri } from '../queries/pages';
import { flatBlockJsonByPageSchema } from '../utilities/helper';
import { HomeSchema } from '../schemas';
// Page Component
export default function Page({ seo, blocks = [] }) {
    // console.log(blocks);
    return (<>
            <SEO fullHead={seo?.fullHead} title={seo?.title}/>
            <Home.Hero title={blocks[0]?.attrbiutes?.title} btnTitle={blocks[0]?.attrbiutes?.primaryButton?.title} to={blocks[0]?.attrbiutes?.primaryButton?.link} description={blocks[0]?.attrbiutes?.description} heroImage={blocks[0]?.attrbiutes?.heroImage.url} helperText={blocks[0]?.attrbiutes?.helperText}/>
            <Home.HowItWorks title={blocks[1]?.attrbiutes?.title} blocks={blocks[1]?.attrbiutes?.blocks}/>
            <Home.Products title={blocks[2]?.attrbiutes?.title} blocks={blocks[2]?.attrbiutes?.blocks}/>
            <Home.Features title={blocks[3]?.attrbiutes?.title} blocks={blocks[3]?.attrbiutes?.blocks}/>
            <Home.CTA {...blocks[4]?.attrbiutes}/>
        </>);
}
Page.getLayout = (page, props) => (<DefaultLayout {...props}>{page}</DefaultLayout>);
async function getStaticProps(ctx) {
    const { locale } = ctx;
    const client = getApolloClient();
    const pageResponse = await getPageByUri(client, '/', locale);
    const blocks = flatBlockJsonByPageSchema(pageResponse.data?.translation?.blocksJSON, HomeSchema);
    const props = getNextStaticProps(ctx, {
        Page,
        client,
        props: {
            seo: pageResponse.data?.seo,
            blocks: blocks || []
        }
    });
    return props;
}

    async function __Next_Translate__getStaticProps__190172dbf11__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: typeof __i18nConfig.loadLocaleFrom === 'function' ? __i18nConfig.loadLocaleFrom : (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__190172dbf11__ as getStaticProps }
  